import env from "react-dotenv";

export default class Settings {
  static get SERVER() {
    //return 'https://license.digipanel.cz';
    //return 'http://localhost:2000';
	return env.SERVER;
	
  }
}
