import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import logo from '../logo.png';

const Dashboard = () => (
    <Card>
        <Title title="Documentation" />
        <CardContent className="doc">
			<h1>Dashboard</h1>
			
			<p>One day, there is going to be an awesome dashboard here.</p>
			
			<p>Have a logo instead:</p>
			
			<img style={{margin: '50px'}} alt="Logo" src={logo} />
			
        </CardContent>
    </Card>
); 

export default Dashboard;