import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import logo from './logo.png';

const styles = ({
    main: { background: '#1a0549 linear-gradient(to right, #221c47, #003061);' },
    avatar: {
        background: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
		backgroundPosition: 'center',
        height: 80,
		margin: 30
    },
    icon: { 
		display: 'none'
	},
});

const CustomLoginForm = withStyles({
    button: { background: '#F15922' },
})(LoginForm);

const CustomLoginPage = props => (
		<div>
			<Login
				loginForm={<CustomLoginForm />}
				{...props}
			/>
		</div>
);

export default withStyles(styles)(CustomLoginPage);