import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  DateInput,
  TextInput,
  required,
  ShowButton,
  CheckboxGroupInput,
  Link
} from 'react-admin';

const ApiKeyFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="project" reference="projects" allowEmpty alwaysOn>
      <SelectInput optionText="project" />
    </ReferenceInput>
    <BooleanInput label="Enabled" source="enabled" defaultValue="true" />
  </Filter>
);

const ApiKeyPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const ApiKeyList = (props) => (
  <List
    {...props}
    filters={<ApiKeyFilter />}
    pagination={<ApiKeyPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField reference="projects" source="project">
		 <TextField source="project" />
	  </ReferenceField>
      <DateField source="created" />
	  <TextField source="scope" />
	  <DateField source="expires" />
	  <BooleanField source="dynamic" defaultValue={false} />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ApiKeyCreate = (props) => (
	<Create {...props}>
		<SimpleForm redirect="show">
			<ReferenceInput reference="projects" source="project" validate={required()}>
				<SelectInput optionText="project" />
			</ReferenceInput>
			<DateInput source="expires" validate={required()} />
			<CheckboxGroupInput source="scope" row={false} choices={[
				{ id: 'crashes', name: 'Crash Reports' },
				{ id: 'logs', name: 'Logs' },
				{ id: 'credentials', name: 'Credentials' },
			]} />
			<TextInput source="secret" label="Public Secret" />
			<TextField source="token" />
			<span style={{"background" : "yellow", "color": "black",
				"font-size" : "1.2em",
			"padding" : "10px"}}>Token will be generated when you save.</span><br />
			<BooleanInput source="dynamic" label="Dynamic (Experimental)" defaultValue={false} />
			{ DynamicTokenInfo }
		</SimpleForm>
	</Create>
);

export const ApiKeyEdit = (props) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput disabled source="id" />
			<ReferenceInput reference="projects" source="project" validate={required()}>
				<SelectInput optionText="project" />
			</ReferenceInput>
			<DateInput source="expires" validate={required()} />
			<CheckboxGroupInput source="scope" row={false} choices={[
				{ id: 'crashes', name: 'Crash Reports' },
				{ id: 'logs', name: 'Logs' },
				{ id: 'credentials', name: 'Credentials' },
			]} />
			<TextInput source="secret" label="Public Secret" />
			<BooleanInput source="dynamic" label="Dynamic (Experimental)" defaultValue={false} />
			{ DynamicTokenInfo }
		</SimpleForm>
	</Edit>
);

export const ApiKeyShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<ReferenceField reference="projects" source="project">
				<TextField source="project" />
			</ReferenceField>
			<DateField source="created" />
			<TextField source="scope" />
			<DateField source="expires" />
			<TextField source="secret" label="Public Secret" />
			<TextField source="token" style={{
				"background" : "yellow",
				"color": "black",
				"font-size" : "1.2em",
				"padding" : "10px"
			}} />
			<BooleanField source="dynamic" defaultValue={false} />
			{ DynamicTokenInfo }
		</SimpleShowLayout>
	</Show>
);

const DynamicTokenInfo = 
	<div style={{"background" : "rgba(0,0,0,0.1)", padding: '10px', width: "auto"}}>
	<p>Dynamic tokens provide an extra layer of security. When enabled, instead of sending the base token in it's pure form, clients need to generate JWT token in this format:</p><pre>
	&#123;
		'token' : '&lt;base token&gt;',
		'expires' : '&lt;choose your date and time&gt;'
	&#125;
	</pre>
	<p>
	Algorithm is HS256 and as the secret, use:
	</p>
	<pre>
		<b>DynamicTokenSecret4AdminApiFortes++</b>
	</pre>
	<p>Example of token with expiration of "1993-05-10T23:59:59":</p>
	<pre style={{
		"max-width" : "500px",
		"overflow-x" : "auto",
		"white-space" : "pre-wrap",
		"word-wrap": "break-word"
	}}>
	eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImJjNzFlNjdmZjgxZDBjODY3ZTY0MDA3NTY3NzZmZjM3YzRiYWE1OWRiZjlmNzI1ZDQ3MjM1ZTQ2NGEzYTMwYWFkOWE2MjU3NjFlMzZiZjJmODhmYzkyY2ZhZGE2YWUwNzM3NGRmZWYyMjUxZjdlMWQzZjgzOTNjNiIsImV4cGlyZXMiOiIxOTkzLTA1LTEwVDIzOjU5OjU5In0.ktBT3hEfH232qthL5sgYKRSc_kGSxfEBcQsTVShSF-M
	</pre>
	<p>You can try to decode it here: <Link href="https://jwt.io/">https://jwt.io/</Link></p>
	<p>This can ensure that a stolen token will expire on it's own because it's ever changing. You can even change it every minute or so. Client chooses the expiration time, server only does the check against it.</p>
	<p>Both client and server know the secret in this case - they are considered to be safe while the transfer medium is not.</p>
	<p>You can still rewoke the base token when using this technique. That still works just like normal API token.</p>
	</div>