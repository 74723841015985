import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

const Documentation = () => (
    <Card>
        <Title title="Documentation" />
        <CardContent className="doc">
			<h1>Crash Reports and Logs</h1>
			
			<p>To use Crash Reports or Logs in your project, you first need to register the Project in ForAdmin. If you wish, you can set a room name for Fortess rocket.chat that will be used for sending messages automatically of any important activity (currently only Crash Reports).</p>
			
			<p>Then you need to create an API Key with the correct scopes. You don't need Public Secret for Crash Reports or Logs.</p>
			
			<p>After you have your key, you can start calling these routes to create a new Crash Report or Log:</p>
			
			<h2>Crash Reports</h2>
			
			<table className="doc-desc">
				<tr><td>Request endpoint:</td><td>POST /crashes</td></tr>
				<tr><td>Required headers:</td><td>authorization: [Your API Token]</td></tr>
				<tr><td>Body elements:</td><td>
					<table>
						<tr><td>identity</td><td>Computer name, IP or whatever you want</td></tr>
						<tr><td>version</td><td>Current version of the app doing the call</td></tr>
						<tr><td>type</td><td>String for categorization and filtering (not an enum)</td></tr>
						<tr><td>error</td><td>A few words describing the error, usually the message of the exception</td></tr>
						<tr><td>detail</td><td>Any other usefull serialized info, for example a stack trace</td></tr>
						<tr><td>config</td><td>Serialized user data with their config files, if supported and needed</td></tr>
						<tr><td>description</td><td>Free for use, usually message from user (for example to describe what they were doing); Can be used in other ways too</td></tr>
					</table>
				</td></tr>
			</table>
			<p><b>Example:</b></p>
			<pre className="code">{`
	Body:
	{
		"identity": "TEST-LUKAS (192.168.0.42)",
		"version": "1.00.00",
		"type": "Update Error",
		"error": "Object referrence not set to an instance of an object",
		"detail": "System.NullReferenceException: Object referrence not set to an instance of an object at MyAwesomeClass.Something ...",
		"config": "{ 'myFavouriteColor' : 'purple', 'hideNavigation' : false }",
		"description": "I clicked an icon and it crashed, plz fix nao!!1!"
	}
			`}</pre>
			
			<h2>Logs</h2>
			<table className="doc-desc">
				<tr><td>Request endpoint:</td><td>POST /logs</td></tr>
				<tr><td>Required headers:</td><td>authorization: [Your API Token]</td></tr>
				<tr><td>Body elements:</td><td>
					<table>
						<tr><td>identity</td><td>Computer name, IP or whatever you want</td></tr>
						<tr><td>version</td><td>Current version of the app doing the call</td></tr>
						<tr><td>type</td><td>String for categorization and filtering (not an enum)</td></tr>
						<tr><td>message</td><td>A few words describing what you are posting</td></tr>
						<tr><td>data</td><td>The actual serialized data</td></tr>
					</table>
				</td></tr>
			</table>
			<p><b>Example:</b></p>
			<pre className="code">{`
	Body:
	{
		"identity": "TEST-LUKAS (192.168.0.42)",
		"version": "1.00.00",
		"type": "Update Log",
		"message": "Client has updated our app",
		"data": "{ 'from-version' : '0.99.99', 'to-version' : '1.00.00', 'system' : 'windows, of course', 'config' : 'idk' }",
	}
			`}</pre>
			
	<br /><b>Note:</b> You don't ever need to specify which project the crash report or log belong to, because the API Key you are using is already tied to it.
	
		<br /><br />
	
		<h1>Credentials</h1>
		
		<p>Credentials are essentialy just user accounts that your app can use, with administration inside of ForAdmin.</p>
		
		<p>Your app can send username and password and ForAdmin will answer with a JWT token for that user, or an error if the credentials are not correct.</p>
		
		<p>To start, you need to register your Project inside of ForAdmin and then create an API Token with "credentials" scope.</p>

		<p>Once done, you need to create a Client which identifies who the credentials belong to (it can be Fortes, it can be some other company...). After that, just create users inside of the Credentials page.</p>
		
		<h2>Request</h2>
		
		<p>Inside of your app, you want to use this endpoint:</p>
		
		<table className="doc-desc">
			<tr><td>Request endpoint:</td><td>POST /check/credentials</td></tr>
			<tr><td>Required headers:</td><td>authorization: [Your API Token]</td></tr>
			<tr><td>Body elements:</td><td>
				<table>
					<tr><td>username</td><td>Name of the user</td></tr>
					<tr><td>password</td><td>Password the user typed in</td></tr>
				</table>
			</td></tr>
		</table>
		<p><b>Example:</b></p>
		<pre className="code">{`
	Body:
	{
		"username": "lukas",
		"password": "fortes1234"
	}
			`}</pre>
		
		<h2>Response</h2>
		
		<p>Server will respond with status of either a 200 in case of successfull credential validation (not really a "login" per se) or 4xx if there is a problem.</p>
		
		<p>A content of the body is a JSON with two attributes - <i>code</i> (copying the response status) and either a <i>message</i> in case of an error, or <i>token</i> if the attempt was successfull.</p>
		<p><b>Successfull example:</b></p>
		<pre className="code">{`
	Body:
	{
		"status": 200,
		"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c [...]"
	}
			`}</pre>
		<p><b>Failed example:</b></p>
		<pre className="code">{`
	Body:
	{
		"status": 404,
		"message": "Invalid credentials"
	}
			`}</pre>
		
		<p>Attribute <i>token</i> is a JWT token that consists of two custom properties, <i>username</i> and <i>userdata</i>, as well as standardized <i>iat</i> and <i>exp</i>.</p>
		
		<p>The token is valid for one hour and can be decoded by using the Public Secret that is set up when generating (or editing) the API Key used for the communication itself. The Public Secret is never actually sent in any requests or responses of any part of the API.</p>
		<p><b>Token payload example:</b></p>
		<pre className="code">{`
	{
		"username": "lukas",
		"userdata": "I like cats.",
		"iat": 1645698967,
		"exp": 1645702567
	}
			`}</pre>
		<p>Algorithm used is HS256.</p>
			
        </CardContent>
    </Card>
); 

export default Documentation;