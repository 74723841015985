import React from 'react';
import { Admin, Resource, fetchUtils, /*translate,*/ DashboardMenuItem, Menu, MenuItemLink, Layout } from 'react-admin';
import { Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import jsonServerProvider from 'ra-data-json-server';
/*import czechMessages from 'ra-language-czech';*/
import englishMessages from 'ra-language-english';
import authProvider from './authProvider';
import Settings from './settings';
import { createTheme } from '@material-ui/core/styles';
import LoginPage from './customLogin';
import Category from './category';
/*import { makeStyles } from '@material-ui/core/styles';*/

// CSS:
import './foradmin-base.css'

// Icons:
import AccountIcon from '@material-ui/icons/AccountBox';
import EventNoteIcon from '@material-ui/icons/EventNote';
import BugReportIcon from '@material-ui/icons/BugReport';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import GroupIcon from '@material-ui/icons/Group';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SettingsIcon from '@material-ui/icons/Settings';
import AttachFile from '@material-ui/icons/AttachFile';
import InfoIcon from '@material-ui/icons/Info';

// Pages:
import Documentation from './pages/documentation'
import Dashboard from './pages/dashboard'

// Resources:
import {
  UserList, UserCreate, UserEdit, UserShow,
} from './pages/users';
import {
  ProjectList, ProjectCreate, ProjectEdit, ProjectShow,
} from './pages/projects';
import {
  ApiKeyList, ApiKeyCreate, ApiKeyEdit, ApiKeyShow,
} from './pages/apiKeys';
import {
  CrashList, CrashShow,
} from './pages/crashes';
import {
  LogList, LogShow,
} from './pages/logs';
import {
  CredentialList, CredentialCreate, CredentialEdit, CredentialShow,
} from './pages/credentials';
import {
  ClientList, ClientCreate, ClientEdit, ClientShow,
} from './pages/clients';

const messages = {
/*  cs: czechMessages,*/
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en');

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(Settings.SERVER, httpClient);

/*messages.cs.resources = {
  users: { name: 'Uživatelé' },
  projects: { name: 'Projekty'},
  apikeys: { name: 'API klíče'},
  
  logs: { name: 'Logy'},
  crashes: { name: 'Crash reporty'},
};
messages.en.resources = {
  apikeys: { name: 'API Keys'},
  
  logs: { name: 'Logs'},
  crashes: { name: 'Crash reports'},
};*/

const theme = createTheme({
 
 menu: {
	width: 220,
    closedWidth: 76
 },
 
  palette: {
    type: 'dark',
	primary: { main: '#00cfff' },
    secondary: { main: '#1a0549' }
	
  },
  
  overrides: {
	  RaSidebar: {
		 fixed: {
		   background: "red"
		 }
	   }
	}
});

const MySidebar = props => {
    return (
		<Menu {...props}>
				<DashboardMenuItem style={{
					margin: '20px 0px 0px 20px'					
				}} to="/" />
				
				<Category title="Analysis">
					<MenuItemLink to="/crashes" primaryText="Crash Reports" leftIcon={<BugReportIcon />}/>
					<MenuItemLink to="/logs" primaryText="Logs" leftIcon={<EventNoteIcon />}/>
				</Category>
				
				<Category title="Licencing">
					<MenuItemLink to="/credentials" primaryText="Credentials" leftIcon={<GroupIcon />}/>
					<MenuItemLink to="/licenses" primaryText="Licenses" leftIcon={<AttachFile />}/>
				</Category>
				
				<Category title="Settings">
					<MenuItemLink to="/projects" primaryText="Projects" leftIcon={<FeaturedPlayListIcon />}/>
					<MenuItemLink to="/clients" primaryText="Clients" leftIcon={<ContactMailIcon />}/>
					<MenuItemLink to="/apikeys" primaryText="API Keys" leftIcon={<VpnKeyIcon />}/>
					<MenuItemLink to="/users" primaryText="Administrators" leftIcon={<SettingsIcon />}/>
				</Category>
				
				<Category title="Other tools">
					<MenuItemLink to="/documentation" primaryText="Documentation" leftIcon={<InfoIcon />}/>
				</Category>
		</Menu>
    );
};

const MyLayout = props => <Layout {...props} sidebar={MySidebar} />

const App = () => (
  <Admin
	theme={theme}
	layout={MyLayout}
	disableTelemetry
	dataProvider={dataProvider}
	i18nProvider={i18nProvider}
	authProvider={authProvider}
	loginPage={LoginPage}
	
	customRoutes={[
		<Route path="/documentation" component={Documentation} />,
		<Route exact path="/" component={Dashboard} />
	]}>
	
	<Resource name="crashes" options={{ label : 'Crash Reports' }} icon={BugReportIcon}
		list={CrashList} show={CrashShow} />
	<Resource name="logs" options={{ label : 'Logs' }} icon={EventNoteIcon}
		list={LogList} show={LogShow} />	
		
	<Resource name="credentials" options={{ label : 'Credentials' }} icon={FeaturedPlayListIcon}
		list={CredentialList} edit={CredentialEdit} create={CredentialCreate} show={CredentialShow} />	
	<Resource name="licenses" options={{ label : 'Licenses' }} icon={AttachFile}
		list={CredentialList} />	
		
	<Resource name="projects" options={{ label : 'Projects' }} icon={FeaturedPlayListIcon}
		list={ProjectList} edit={ProjectEdit} create={ProjectCreate} show={ProjectShow}  />
	<Resource name="clients" options={{ label : 'Clients' }} icon={FeaturedPlayListIcon}
		list={ClientList} edit={ClientEdit} create={ClientCreate} show={ClientShow}  />
	<Resource name="apikeys" options={{ label : 'API Keys' }} icon={VpnKeyIcon}
		list={ApiKeyList} edit={ApiKeyEdit} create={ApiKeyCreate} show={ApiKeyShow} />	
	<Resource name="users" options={{ label : 'Users' }} icon={AccountIcon}
		list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} />
	
  </Admin>
);

export default App;
