import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ProjectFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="project" reference="projects" allowEmpty alwaysOn>
      <SelectInput optionText="project" />
    </ReferenceInput>
    <BooleanInput label="Enabled" source="enabled" defaultValue="true" />
  </Filter>
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const ProjectList = (props) => (
  <List
    {...props}
    filters={<ProjectFilter />}
    pagination={<ProjectPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="project" />
	  <BooleanField source="enabled" />
      <DateField source="created" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ProjectCreate = (props) => (
	<Create {...props}>
		<SimpleForm redirect="show">
			<TextInput source="project" validate={required()} />
			<BooleanInput source="enabled" defaultValue />
			<RichTextInput source="description" />
			<span>Rocket.Chat channel for automated messages: </span>
			<TextInput source="channel" />
		</SimpleForm>
	</Create>
);

export const ProjectEdit = (props) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput disabled source="id" />
			<TextInput source="project" validate={required()} />
			<BooleanInput source="enabled" defaultValue />
			<RichTextInput source="description" />
			<span>Rocket.Chat channel for automated messages: </span>
			<TextInput source="channel" />
		</SimpleForm>
	</Edit>
);

export const ProjectShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<DateField disabled source="created" />
			<TextField source="project" />
			<BooleanField source="enabled" />
			<RichTextField source="description" />
			<TextField source="channel" />
		</SimpleShowLayout>
	</Show>
);