import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  PasswordInput,
} from 'react-admin';

import { DateTimeInput } from 'react-admin-date-inputs';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const EXPIRING_DAYS = 30;

const licenseStyles = makeStyles({
  valid: { background: 'rgba(158, 255, 143, 0.25)' },
  expire_soon: { background: 'rgba(255, 239, 99, 0.25)' },
  expired: { background: 'rgba(255, 99, 99, 0.25)' },
  disabled: { background: 'rgba(170, 170, 170, 0.25)' },
});

function getLicenseStyleClass(date, option, disabled = false) {
  const licenseDate = new Date(date);
  const now = new Date();
  const expireSoon = new Date();
  expireSoon.setDate(now.getDate() + EXPIRING_DAYS);

  if (option === 'disabled') {
    return disabled;
  }
  if (option === 'valid') {
    return licenseDate > expireSoon;
  }
  if (option === 'expire_soon') {
    return licenseDate > now && licenseDate < expireSoon;
  }
  if (option === 'expired') {
    return licenseDate < now;
  }

  return false;
}

const ColoredDateField = (props) => {
  const classes = licenseStyles();
  return (
    <DateField
      className={classnames({
        [classes.valid]: getLicenseStyleClass(props.record[props.source], 'valid'),
        [classes.expire_soon]: getLicenseStyleClass(props.record[props.source], 'expire_soon'),
        [classes.expired]: getLicenseStyleClass(props.record[props.source], 'expired'),
        [classes.disabled]: getLicenseStyleClass(props.record[props.source], 'disabled', !props.record['enabled']),
      })}
      showTime
      {...props}
    />
  );
};

const CredentialFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="project" reference="projects" allowEmpty alwaysOn>
      <SelectInput optionText="project" />
    </ReferenceInput>
	<ReferenceInput source="name" reference="clients" allowEmpty>
      <SelectInput optionText="client" />
    </ReferenceInput>
    <BooleanInput label="Enabled" source="enabled" defaultValue="true" />
  </Filter>
);

const CredentialPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const CredentialList = (props) => (
  <List
    {...props}
    filters={<CredentialFilter />}
    pagination={<CredentialPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
	  <ReferenceField reference="projects" source="project">
		 <TextField source="project" />
	  </ReferenceField>
	  <ReferenceField reference="clients" source="client">
		 <TextField source="name" />
	  </ReferenceField>
      <BooleanField source="enabled" />
      <DateField source="created" />
      <ColoredDateField source="expiration" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const CredentialCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
	  <ReferenceInput reference="projects" source="project" validate={required()}>
		  <SelectInput optionText="project" />
	  </ReferenceInput>
	  <ReferenceInput reference="clients" source="client">
		  <SelectInput optionText="name" />
	  </ReferenceInput>
	  <TextInput source="username" validate={required()} resettable label="User" />
      <PasswordInput source="password" validate={required()} label="Password" />
	  <PasswordInput source="password-again" validate={required()} label="Password again" />
      <DateTimeInput source="expiration" label="Expiration" validate={required()} options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true, }} />
      <BooleanInput source="enabled" initialValue={true} />
	  <TextInput source="userdata" label="User Data" />
    </SimpleForm>
  </Create>
);

export const CredentialEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="edit">
	  <ReferenceInput disabled reference="projects" source="project" validate={required()}>
		  <SelectInput optionText="project" />
	  </ReferenceInput>
	  <ReferenceInput disabled reference="clients" source="client">
		  <SelectInput optionText="name" />
	  </ReferenceInput>
	  <TextInput disabled source="username" validate={required()} resettable label="User" />
      <TextInput source="new-password" type="password" label="New password" />
	  <TextInput source="new-password-again" type="password" label="New password again" />
      <DateTimeInput source="expiration" label="Expirace" validate={required()} options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true, }} />
      <BooleanInput source="enabled" />
	  <TextInput source="userdata" label="User Data" />
    </SimpleForm>
  </Edit>
);

export const CredentialShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <BooleanField source="enabled" />      
	  <ReferenceField reference="projects" source="project">
		<TextField source="project" />
	  </ReferenceField>
	  <ReferenceField reference="clients" source="client">
		<TextField source="name" />
	  </ReferenceField>
      <TextField source="username" label="User" />
      <DateField source="created" showTime="true" />
      <DateField source="expiration" showTime="true" />
	  <TextField source="userdata" label="User Data" />
    </SimpleShowLayout>
  </Show>
);
